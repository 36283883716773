import React, { Component } from 'react';
import { createRef } from 'react';
import { podcasts } from '../utils/podcasts';


class Albums extends Component {
  constructor(props) {
    super(props);
    this.scrollBox = createRef();
  }

  state = { mouseDown: false, position: { left: 0, x: 0 }, currentLeft: 0, currentPodcastScroll: 0 };

  onDrag = (event) => {
    if (this.state.mouseDown && event.target) {
      const dx = event.clientX - this.state.position.x;
      this.scrollBox.current.scrollLeft = this.state.position.left - dx;
    }
  };

  dragSelect = () => {
    if (this.scrollBox.current.scrollLeft % (window.innerWidth >= 576 ? 240 : 248) === 0) {
      const position = this.scrollBox.current.scrollLeft / (window.innerWidth >= 576 ? 240 : 248);
      this.setState({ currentPodcastScroll: position }, () => {
        this.props.selectPodcast(position);
      });
    }
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.dragSelect());
  };

  render() {
    return (
      <div
        className="albums-container albums-patting"
        onScroll={() => this.dragSelect()}
        ref={this.scrollBox}
        onMouseDown={(event) => {
          this.scrollBox.current.style.cursor = 'grabbing';
          this.scrollBox.current.style.scrollSnapType = 'none';
          this.setState({ mouseDown: true, position: { left: this.scrollBox.current.scrollLeft, x: event.clientX }, clickLock: true });
        }}
        onMouseUp={() => {
          this.scrollBox.current.style.cursor = 'grab';
          if (navigator.userAgent.includes('Chrome')) {
            this.scrollBox.current.style.scrollSnapType = 'x mandatory';
          }
          this.setState({ mouseDown: false, currentLeft: this.scrollBox.current.scrollLeft });
        }}
        onMouseMove={(event) => this.onDrag(event)}>
        {podcasts.map((pod, index) => {
          return (
            <div
              key={index}
              className="album"
              onClick={(event) => {
                if (this.state.currentLeft === this.state.position.left) {
                  this.props.selectPodcast(index);
                  this.scrollBox.current.scrollTo({ left: (event.target.offsetWidth + (window.innerWidth >= 576 ? 40 : 48)) * index, behavior: 'smooth' });
                }
              }}>
              <div className="album-content" style={{ backgroundImage: `url(${pod.cover})`, boxShadow: this.state.currentPodcastScroll !== index ? '0px 2px 5px rgba(0,0,0,0.1)' : 'var(--LVO-shadow)', transform: this.state.currentPodcastScroll !== index ? 'scale(1)' : 'scale(1.025)' }}>

              <a href='#Album' className="anchor-ref">


                
              </a>
              </div>

              <h1>{pod.title}</h1>
              <h2>{pod.episodes.length} Episoden</h2>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Albums;

/*
boxShadow: '0px 2px 5px rgba(0,0,0,0.1)'
*/
