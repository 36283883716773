export const podcasts = [
  {
    title: 'liebesdinge by LOVOO',
    description: (
      <>
        Endlich erzählen uns LOVOO Paare wie sie sich kennenlernen, welche Höhen und Tiefen sie zusammen meistern mussten, über erste Streits, Familienplanung, Fernbeziehung und und und.
        Zum Hörvergnügen trägt das Moderatorenduo Ryo Takeda (u.a. bekannt durch MDR & BuVisCo) und Antonio Lucaciu (u.a. bekannt durch Clueso & Udo Lindenberg) bei. 
        Beide sind etablierte Musiker und vor allem Freunde. Mit ihrer Dynamik schaffen sie es, den LOVOO Pärchen die wirklich interessanten Details zu entlocken.
        <br />
        <br />
        Mach es dir gemütlich und lausche den romantischen Geschichten unserer LOVOO Paare. Neue Folgen veröffentlichen wir alle zwei Wochen bei Apple Podcasts und Spotify.
      </>
    ),
    cover: `${process.env.PUBLIC_URL}/covers/liebesdinge.jpg`,
    podcast_spotify: 'https://open.spotify.com/show/7f7epauMhiMaz1yVmTEEwX?si=3c016bd427c841d8',
    podcast_itunes: 'https://podcasts.apple.com/us/podcast/liebesdinge-by-lovoo/id1609103175',
    episodes: [
      { title: 'Da war die Katze aus dem Sack – Janine & Steve', 
      description: 'Nach zehn Jahren Beziehung und Ehe waren Janine und Steve beide wieder Single und alles stand auf Null. Dann lernten sie sich auf LOVOO kennen. Warum Steve’s eigener Podcast dabei eine wichtige Rolle spielt und warum Katzencontent auch im Chat gut ankommt, erfahrt in dieser allerersten Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/liebesdinge-by-lovoo/id1609103175', 
      spotify: 'https://open.spotify.com/show/7f7epauMhiMaz1yVmTEEwX?si=3c016bd427c841d8', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/1.%20Folge%20Janine%20%26%20Steve.mp3', 
      shortcode: 's01e01' },
      
      { title: 'Mit Vollgas ins Liebesglück – Ayleen & Mig', 
      description: 'Dass Mig und Ayleen sich getroffen haben, verdanken sie in erster Linie der Live-Streaming-Funktion von LOVOO - und einem gemeinsamen Freund. Wie genau sich die beiden getroffen haben, was ein “weiblicher Rucksack” ist und warum zwölf Jahre Altersunterschied kein Problem sind, hört ihr in der neuesten Folge unseres Podcasts. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/liebesdinge-by-lovoo/id1609103175', 
      spotify: 'https://open.spotify.com/show/7f7epauMhiMaz1yVmTEEwX?si=3c016bd427c841d8', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/2.%20Folge%20Ayleen%20%26%20Mig.mp3', 
      shortcode: 's01e02' },
    
      { title: 'Ein Kuss im Regen – Filiz & Gila', 
      description: 'Es ist ein kalter, verregneter Novembertag, als Gila im Schein der Straßenlaterne alles auf eine Karte setzt. Die Beziehung beginnt mit einem Kuss im Regen. Welche Vorsätze Gila und Filiz füreinander über Bord werfen und was nach dem Kuss in sehr kurzer Zeit passiert ist, erfahrt ihr in dieser Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/ein-kuss-im-regen-filiz-gila/id1609103175?i=1000552387079', 
      spotify: 'https://open.spotify.com/episode/0ycZFQzYH9dMZPsp8KPq1C?si=862fad83820c417c', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/3.%20Folge%20Filiz%20%26%20Gila.mp3', 
      shortcode: 's01e03' },

      { title: 'Zu Lasagne passt Likör – Nicole & Kevin', 
      description: 'Bei Nicole und Kevin lief alles ein bisschen anders: Obwohl beide einen gemeinsamen Freundeskreis haben, sind sie sich vorher nie über den Weg gelaufen. Bei LOVOO ging dann alles ganz schnell – zwei Tage angemeldet, dann schon ein erstes Date mit Lasagne und Likör. Zu welchem Song beide beim Carpool Karaoke abgehen und welches Partner-Tattoo sie geplant haben, hört ihr in dieser Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/zu-lasagne-passt-likör-nicole-kevin/id1609103175?i=1000553888777', 
      spotify: 'https://open.spotify.com/episode/4MUnqONmD1NJP0biKhyXJt?si=PDCPfKSrRxmVeVpOsw4wtQ&nd=1', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/4.%20Folge%20Nicole%20%26%20Kevin.mp3', 
      shortcode: 's01e04' },

      { title: 'Vom Winde verweht – Franziska & Marcel', 
      description: 'Nicht gesucht und trotzdem gefunden: Ohne Erwartungen nutzten Franzi und Marcel LOVOO und fanden an einem stürmischen Tag zueinander. In kürzester Zeit entwickelte sich zwischen den beiden eine Liebe ohne Kompromisse, die auch den stärksten Windböen standhält. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/vom-winde-verweht-franziska-marcel/id1609103175?i=1000555396596', 
      spotify: 'https://open.spotify.com/episode/2xhS19g2cQmKhbs5Bp9SBN?si=df2cc95aaac64bdd&nd=1', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/5.%20Folge%20Franzi%20%26%20Marcel.mp3', 
      shortcode: 's01e05' },
      
      { title: 'Wenn aus Freundschaft Liebe wird – Marietta & Stefan', 
      description: 'Marietta und Stefan lernten sich über LOVOO kennen, wollten es jedoch langsam angehen lassen und blieben zunächst befreundet. Wie die beiden zueinander fanden und aus Freundschaft Liebe wurde, erfährst du in unserer neuen Podcast-Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/wenn-aus-freundschaft-liebe-wird-marietta-stefan/id1609103175?i=1000556918709', 
      spotify: 'https://open.spotify.com/episode/0aR4pbyTQtrXmDAGEoYfrp?si=f5941d430d834d0c', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/6.%20Folge%20Marietta%20%26%20Stefan.mp3', 
      shortcode: 's01e06' },

      { title: 'Ein Sieg für die Liebe – Jasmin & Sven', 
      description: 'Jasmin nutzte LOVOO ohne große Erwartungen und war von Svens authentischem Profil begeistert. Schnell merkten die beiden Leistungssportler, dass sie auf einer Wellenlänge sind, führten lange Telefonate und hatten eine intensive Kennenlernphase. Doch dann war drei Monate Funkstille. Aus welchem Grund und wie die junge Liebe trotzdem zueinander gefunden hat, hört ihr in unserer neuen Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/ein-sieg-f%C3%BCr-die-liebe-jasmin-sven/id1609103175?i=1000558545667', 
      spotify: 'https://open.spotify.com/episode/4pBQPb0wyab69zHdZ3AGq9?si=901a11d6d2c045fa', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/7.%20Folge%20Jasmin%20%26%20Sven.mp3', 
      shortcode: 's01e07' },

      { title: 'Butter bei die Fische – Charleen & Lisa', 
      description: 'Ohne viel zu überlegen, setzte sich die 18 jährige Charly in den Zug nach Kiel, um Lisa an ihrem Geburtstag zu überraschen. Es war ihr erstes persönliches Treffen. Doch der Mut wurde belohnt. Beiden war klar: Sie ist die Richtige. Sechs Jahre später war es Lisa, die “Butter bei die Fische” machte. Wie die Verlobung ablief und was in den sechs Jahren alles passiert ist, das erfahrt in dieser Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/butter-bei-die-fische-charleen-lisa/id1609103175?i=1000560011983', 
      spotify: 'https://open.spotify.com/episode/1Ejybg07SPb7ZmQlXV2MJ5', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/8.%20Folge%20Charly%20%26%20Lisa.mp3', 
      shortcode: 's01e08' },

      { title: 'Mit angezogener Handbremse ins Liebesglück – Julia & Marcus', 
      description: 'Während sich Julia ihren Gefühlen gegenüber Marcus schnell klar wurde, benötigte dieser mehr Zeit. Dies war ihr zunächst nicht bewusst und kam erst bei einem Vorschlag, die beste Freundin vorzustellen, zum Vorschein. Doch die Geduldsprobe hat sich gelohnt, denn nach einem gemeinsamen Ausflug löste Marcus seine innere Handbremse. Wie es dazu kam und was den beiden bevorsteht, erfährst du in unserer neuen Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/mit-angezogener-handbremse-ins-liebesgl%C3%BCck-julia-marcus/id1609103175?i=1000563220311', 
      spotify: 'https://open.spotify.com/episode/1gwCefSDeY9I419xd0zuBJ?si=ba44258992ef4364', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/9.%20Julia%20%26%20Marcus.mp3', 
      shortcode: 's01e09' },
      
      { title: 'Die erste Sprachnachricht aller Zeiten – Claudia & Robert', 
      description: 'Robert hatte noch nie eine Voicemail verschickt, bis er von Claudia aus seiner Komfortzone herausgekitzelt wurde. Zwar waren beide schnell auf einer Wellenlänge, wollten jedoch auf Nummer sichergehen, ob der Vibe wirklich passt und hatten hierfür ihre ganz eigenen Methoden und Prinzipien – so musste Robert Claudias Wohnung bereits nach 5 Minuten verlassen. Was sich hinter dieser Kennenlerngeschichte verbirgt, erfahrt ihr in unserer neuen Folge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/die-erste-sprachnachricht-aller-zeiten-claudia-robert/id1609103175?i=1000565324246', 
      spotify: 'https://open.spotify.com/episode/2ifBXFb3bq1IhRI8jqLfkN?si=3a8e9ef5b0354815', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/10.%20Claudia%20%26%20Robert.mp3', 
      shortcode: 's01e10' },

      { title: 'Losgelöst nach 10 Jahren Ehe – Micky & Kadda', 
      description: 'Den eignen Bedürfnissen nachgehen und auf Gefühle hören – für Micky und Kadda war es der Schritt zum Glück! Beide Frauen verbindet eine ähnliche Geschichte: Eine gescheiterte Ehe mit einem Mann, Mama von zwei Kindern und die Suche nach der wahren Liebe. Beide Frauen haben sich von ihrer Vergangenheit losgelöst und selbst verwirklicht. Über LOVOO haben sie sich kennen und lieben gelernt – heute leben sie in einer gemeinsamen Patchworkfamilie. Erfahre in unserer neuen Episode, wie es zu diesem Neuanfang für beide kam und wie ein zunächst kommentarloser Heiratsantrag trotzdem mit JA beantwortet wurde. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/losgel%C3%B6st-nach-10-jahren-ehe-micky-kadda/id1609103175?i=1000567087609', 
      spotify: 'https://open.spotify.com/episode/116bfAQEdXPfM7q0gru5Jq?si=bc653cd880f94ab9', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/11.%20Folge%20Micky%20%26%20Kadda.mp3', 
      shortcode: 's01e11' },

      { title: 'Nächste Ausfahrt: Liebesglück – Fabienne & Torben', 
      description: 'Nach 2 Jahren Funkstille, lief das erste Treffen zwischen Fabienne und Torben ganz anders als erwartet: Torben wollte mit seinen Jungs zu einem Schützenfest fahren. Allerdings fehlte eine Person, die fährt. Kurzerhand fragte er Fabienne, ob sie sich auf dieses Abenteuer einlassen wollen würde. Das tat sie auch – obwohl sich die beiden zuvor noch nie persönlich getroffen hatten. Torbens Befürchtung, in die Friendzone gerutscht zu sein, löste sich wenige Tage nach dem ersten Treffen in Luft auf. Wie es weiterging, erfahrt ihr in der neuen Folge von liebesdinge. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/n%C3%A4chste-ausfahrt-liebesgl%C3%BCck-fabienne-torben/id1609103175?i=1000568690244', 
      spotify: 'https://open.spotify.com/episode/7tFmTSU18vmCQ7OkKfuL4u?si=dbff45db822646ee', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/12.%20Fabienne%20%26%20Torben.mp3', 
      shortcode: 's01e12' },

      { title: 'Ein nächtlicher Tanz auf den Straßen Würzburgs – Lisa & Markus', 
      description: 'Fast hätte es kein erstes Date zwischen Lisa und Markus gegeben, denn Markus kam aufgrund eines spannenden Fußballspiels zu spät zur Verabredung. Obwohl Lisa nicht länger warten wollte und sich bereits auf den Heimweg machte, hatte das Schicksal andere Pläne: Markus lief ihr im selben Moment auf dem gleichen Weg entgegen und traf sie, wodurch die beiden doch noch gemeinsam den Abend mit Wein und einem romantischen Tanz auf den Straßen Würzburgs ausklingen ließen. Höre jetzt in unsere neue Episode und erfahre mehr über die Geschichte von Lisa und Markus. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/ein-n%C3%A4chtlicher-tanz-auf-den-stra%C3%9Fen-w%C3%BCrzburgs-lisa-markus/id1609103175?i=1000570261795', 
      spotify: 'https://open.spotify.com/episode/1yvOW0t0yLuSr0Imhykm5B?si=456257f3603d40d0', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/13.%20Lisa%20%26%20Markus.mp3', 
      shortcode: 's01e13' },

      { title: 'Der Sternschnuppenwunsch - Fabienne & Marvin', 
      description: 'Geprägt von den vorherigen Beziehungen, wollten Fabienne und Marvin nichts überstürzen und gingen zunächst eine Freundschaft mit gewissen Vorzügen ein. Schnell kristallisierte sich heraus, dass beide auf einer Wellenlänge sind. Als Fabienne eines Nachts eine Sternschnuppe am Himmel sichtete, wünschte sie sich eine ernste Beziehung mit Marvin. Kurze Zeit später ging dieser Wunsch auch in Erfüllung, denn Marvin gestand ihr seine Liebe. Höre jetzt in unsere neue Episode und erfahre mehr über die Geschichte von Fabienne und Marvin. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/der-sternschnuppenwunsch-fabienne-marvin/id1609103175?i=1000574595811', 
      spotify: 'https://open.spotify.com/episode/2AJdnjAHyKbrb2D84UkvLh?si=81c9969252684877', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/14.%20Fabienne%20%26%20Marvin.mp3', 
      shortcode: 's01e14' },

      { title: 'Liebe kennt keine Grenzen - Rebekka & Sebastian', 
      description: 'Durch eine genetische Erkrankung beschränkt sich Rebekkas Sehvermögen auf lediglich 3 %. Das hinderte sie jedoch nicht daran, auf LOVOO nach der großen Liebe zu suchen. Beim ersten Date mit Sebastian berichtete sie nicht direkt über ihr Handicap. Erst nachdem sie eine verschobene Gummibärchentüte hätte ertasten müssen, öffnete sich Rebekka – doch das schreckte Sebastian nicht ab. Beide haben sich auf das gemeinsame (Liebes-)Abenteuer eingelassen und sind ein eingespieltes Team geworden. Höre jetzt in unsere letzte Episode vor der Sommerpause und erfahre mehr über die besondere Geschichte von Rebekka und Sebastian. Viel Spaß!', 
      itunes: 'https://podcasts.apple.com/us/podcast/liebe-kennt-keine-grenzen-rebekka-sebastian/id1609103175?i=1000576077737', 
      spotify: 'https://open.spotify.com/episode/30EY3l1fmYsoqHCfHMNNo2?si=7HvVUYHjSrOgiVazhxBHGA', 
      url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/15.%20Rebekka%20%26%20Sebastian.mp3', 
      shortcode: 's01e15' },
    ],
  },

  {
    title: 'Dein Date mit Ronja',
    description: (
      <>
        Eine positive Einstellung zum Leben und ein gutes Herz soll er haben, lieber Bauch- als Kopfmensch und begeisterungsfähig sein, so stellt sich Ronja ihren Traummann vor.
        <br />
        <br />
        Hat die sympathische Stimme dich in ihren Bann gezogen? Dann melde dich doch bei uns auf Facebook oder Instagram und vielleicht bekommst du bald selbst die Chance, Ronja kennenzulernen.
      </>
    ),
    cover: `${process.env.PUBLIC_URL}/covers/ronja.jpg`,
    podcast_spotify: 'https://open.spotify.com/show/12wD1aYAIBZilKsRyVMXnA',
    podcast_itunes: 'https://podcasts.apple.com/de/podcast/dein-date-mit-ronja-der-lovoo-podcast/id1488193556',
    episodes: [
      { title: 'Mädelsabend', description: 'Drei Single-Frauen auf der Couch unterhalten sich. Ja, so interessant wie das schon klingt, wird es in der ersten Episode auch. Ronja, Sabrina und Tina sprechen bei einem Glas Wein übers Online-Dating, über furchtbar langweilige oder furchtbar schlechte erste Dates, über Männer mit Tattoos und Bart und über Chatinhalte, die wirklich gar nicht gehen. Mehr hörst du in der ersten Folge.', itunes: 'https://podcasts.apple.com/de/podcast/m%C3%A4delsabend/id1488193556?i=1000456954393', spotify: 'https://open.spotify.com/episode/0awysewFlSO4nVzkLSUUly', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode1.mp3', shortcode: 's01e01' },
      { title: 'Elefant trifft Flughörnchen', description: 'Der erste Mann, den Ronja datet, ist Basheer. Im Zoo treffen sie auf süße Babyaffen, finden heraus, welches Tier der andere wäre und tauschen sich über rührende Haustiergeschichten aus. Eines davon hat sogar einen Schönheitswettbewerb gewonnen… Welches Tier das war, was Basheer Spannendes zu erzählen hatte und ob die Funken sprühten, erfährst du in der zweiten Folge.', itunes: 'https://podcasts.apple.com/de/podcast/elefant-trifft-flugh%C3%B6rnchen/id1488193556?i=1000457192043', spotify: 'https://open.spotify.com/episode/1XkNdu4TqCs45JhkVcAGBS', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode2.mp3', shortcode: 's01e02' },
      { title: 'Date mit Absturz?', description: 'Wart ihr schon einmal mit jemandem klettern, der Höhenangst hat…? - Ich auch nicht! Mein Date Frank stellt sich jedoch seiner Höhenangst und wir treffen uns in der Kletterbar in Offenbach. Hoffentlich ohne Absturz… Mehr erfährst du in der dritten Folge.', itunes: 'https://podcasts.apple.com/de/podcast/date-mit-absturz/id1488193556?i=1000458115594', spotify: 'https://open.spotify.com/episode/6JszVz8aSQlApWIRSMCOdy', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode3.mp3', shortcode: 's01e03' },
      { title: 'Glühwein Deep Talk', description: 'Mein Date Paul möchte mit mir hoch hinaus: beim CityXmas über den Dächern von Frankfurt geht unser Gespräch in die Tiefe. Und ihr hört, warum wir Fränkisch mögen und schon länger Single sind.', itunes: 'https://podcasts.apple.com/de/podcast/gl%C3%BChwein-deep-talk/id1488193556?i=1000458775675', spotify: 'https://open.spotify.com/episode/4L22Tvv1U42ieYc6z5PHg5', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode4.mp3', shortcode: 's01e04' },
      { title: 'Bar-Flirt', description: 'Bei der „Shake Night“ lernen mein Date Marlon und ich Mai Tai mixen. Bei guten Drinks in der Frankfurter Bar Rot & Vogel reden wir über unsere Schüchternheit, krasse Ausraster und wer für Dates absolut nicht in Frage kommt.', itunes: 'https://podcasts.apple.com/de/podcast/bar-flirt/id1488193556?i=1000459415692', spotify: 'https://open.spotify.com/episode/5Avo1HfKmYCkqmQHr7ZcAX', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode5.mp3', shortcode: 's01e05' },
      { title: 'Typen-Check', description: 'Marlon und ich treffen uns zu einem 2. Date, um zu kochen und uns weiter kennenzulernen... Und mit meiner besten Freundin Tina spreche ich über alle Männer in diesem Date-Podcast. Wer passt zu mir? Wer hat Ecken? Und was war eigentlich mit dem Angestellten aus der Kletterhalle?!', itunes: 'https://podcasts.apple.com/de/podcast/typen-check/id1488193556?i=1000460166248', spotify: 'https://open.spotify.com/episode/1hwhPgdLZihl81AFEIcmMk', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/DeinDateMitRonja_Episode6.mp3', shortcode: 's01e06' },
    ],
  },
  {
    title: 'Geile Liebe',
    description: (
      <>
        Sie sind jung, sie sind grundverschieden und lieben sich trotzdem und vor allem wollen sie die Power of Love in dein Ohr und Leben bringen.Die Freundinnen Dani und Lena sind die Stars des neuen Podcasts “Geile Liebe” powered by LOVOO und haben eine Mission: <b>es soll mehr glückliche Beziehungen auf dieser Welt geben</b>.<br />
        <br />
        Mit einer bunten Themenmischung zu Liebe, Dating, Freundschaft, Achtsamkeit und Sex ist “Geile Liebe”, nicht einfach nur der nächste Beziehungspodcast, sondern ab jetzt dein regelmäßiger Begleiter, deine Anlaufstelle, wenn du etwas zum Lachen, Nachdenken und Lernen brauchst.
      </>
    ),
    cover: `${process.env.PUBLIC_URL}/covers/geileliebe.jpg`,
    podcast_spotify: 'https://open.spotify.com/show/0CLsphO8HdzEclxdzGGW6g?si=-yxPmOaGRy-Y4HNvT6T1WQ&dl_branch=1',
    podcast_itunes: 'https://podcasts.apple.com/de/podcast/love-meet-up/id1520532938',
    episodes: [
      { title: 'Wenn aus online offline wird', description: 'Dates können so wundervoll sein! – Können. In der ersten Folge von “Geile Liebe” erzählen Dani und Lena von ihren schönsten und schlimmsten Dates. Erfahre, welche der beiden den Nervenkitzel liebt, wenn aus online offline wird, was bei einem Date so alles schief gehen kann und warum wir alle, ab jetzt unsere Checkliste zu Hause lassen sollten.', itunes: 'https://podcasts.apple.com/de/podcast/online-vs-offline-dating/id1520532938?i=1000479708615', spotify: 'https://open.spotify.com/episode/2Lck9plTOnOZPUD7hIqreR', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode01.mp3', shortcode: 's02e01' },
      { title: 'Das perfekte Date', description: 'In Folge 2 werden Elementarfragen rund ums erste Date geklärt. Lena verrät uns, wie sie Dates strukturiert. Und bei dem Thema, was bei Männern gar nicht geht und wer die Rechnung zahlt, sind sich beide einig. Reinhören und lernen.', itunes: 'https://podcasts.apple.com/de/podcast/das-perfekte-date/id1520532938?i=1000482150484', spotify: 'https://open.spotify.com/episode/2RUUbO5psOh7IpssyyrbI8', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode02.mp3', shortcode: 's02e02' },
      { title: 'Fernbeziehung', description: 'Welche Vorteile bringt die Distanz in einer Fernbeziehung und welche Hürden stecken dahinter? Hamburg, Leipzig, Mainz, Dresden, Berlin - welche Distanz ist für euch ok und wie kann man die Distanz überbrücken?', itunes: 'https://podcasts.apple.com/de/podcast/fernbeziehung/id1520532938?i=1000484016684', spotify: 'https://open.spotify.com/episode/4XOvYkgafgvCXTzk7zgFDT', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode03.mp3', shortcode: 's02e03' },
      { title: 'Sprachen der Liebe', description: 'Sprecht ihr mit eurem Partner/eurer Partnerin in der gleichen Sprache? Es soll verschiedene Arten geben, wie wir Liebe zeigen und empfangen - die sogenannten Sprachen der Liebe. Welche das sind, erzählen euch Dani und Lena vom Geile Liebe Podcast in der neuen Folge.', itunes: 'https://podcasts.apple.com/de/podcast/die-sprachen-der-liebe/id1520532938?i=1000485223460', spotify: 'https://open.spotify.com/episode/6BUvOaC5cOagJCk0I8mFH6', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode03_2.mp3', shortcode: 's02e04' },
      { title: 'Gemeinsam oder alleine reisen?', description: 'Urlaub alleine, mit Freunden oder als Paar? Dani & Lena haben alles schon mal ausprobiert und sprechen in der neuen Folge “Gemeinsam oder alleine reisen?” darüber.', itunes: 'https://podcasts.apple.com/de/podcast/gemeinsam-oder-alleine-reisen/id1520532938?i=1000485879567', spotify: 'https://open.spotify.com/episode/1GlbzDM6GB3ciZ8xqDESbx', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode04.mp3', shortcode: 's02e05' },
      { title: 'Darf sich Familie einmischen?', description: 'Wer kennt es nicht, wenn die Eltern ihren Senf zum neuen Partner dazugeben? Bis wann ist das ok? Wo ist die Grenze? Dani & Lena sprechen über die Erfahrungen, die sie mit ihren eigenen Eltern sowie den Eltern ihrer Partner gesammelt haben.', itunes: 'https://podcasts.apple.com/de/podcast/darf-sich-familie-einmischen/id1520532938?i=1000486643715', spotify: 'https://open.spotify.com/episode/00Y3YPEpLUpSp8181BPCu1', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode05.mp3', shortcode: 's02e06' },
      { title: 'Lückenfüller', description: 'Gleich wieder daten? Oder lieber etwas warten? Dani & Lena schauen in der neuen Folge zurück und diskutieren darüber, ob es "Lückenfüller" gibt und wenn ja, wie man ihnen das kommuniziert.', itunes: 'https://podcasts.apple.com/de/podcast/l%C3%BCckenf%C3%BCller/id1520532938?i=1000487348025', spotify: 'https://open.spotify.com/episode/4USwepFkYaKj9qToh6RTkI', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode06.mp3', shortcode: 's02e07' },
      { title: 'Gibt es beste Freund*innen?', description: 'Gibt es so etwas wie beste Freund*innen überhaupt und wenn ja, woran machen wir das fest? Gibt es Freundschaft zwischen Männern und Frauen und was sind die Grenzen einer engen Freundschaft? Dani & Lena tauschen sich über ihre Erfahrungen und Sichtweisen aus und lassen euch daran teilhaben.', itunes: 'https://www.lovoo.com/podcast/podcast/applepodcasts_logo.png', spotify: 'https://www.lovoo.com/podcast/podcast/spotify_logo.png', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode07.mp3', shortcode: 's02e08' },
      { title: 'Schlussmachen mit Freunden', description: 'Habt ihr schon mal unter Freunden "schluss" gemacht oder ghostet ihr den Anderen einfach? Wie bekommt man so einen "Knick" wieder raus?', itunes: 'https://podcasts.apple.com/de/podcast/schlussmachen-mit-freunden/id1520532938?i=1000488715764', spotify: 'https://open.spotify.com/episode/0FiDcGzUf8nFQDmoL6vwYq?si=xebjuUHIQqupP6VAJ5uPsQ', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode08.mp3', shortcode: 's02e09' },
      { title: 'Toxische Beziehungen', description: 'Hattest du schon einmal das Gefühl, deine Beziehung ist eher schädlich, als dass sie deinem Wohlbefinden dient? Kontrollzwang, Demütigung, Traurigkeit - Gefühle die unserer Meinung nach nichts in einer Beziehung verloren haben. Wir tauschen unsere Erfahrungen aus und lassen euch daran teilhaben.', itunes: 'https://podcasts.apple.com/de/podcast/toxische-beziehungen/id1520532938?i=1000489346163', spotify: 'https://open.spotify.com/episode/65TqN4VKgMwxKiKO8ifJQ0', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode09.mp3', shortcode: 's02e10' },
      { title: 'Selbstzweifel', description: 'Wo stehen wir uns selbst im Weg, weil wir nicht an uns glauben? Kennst du das Gefühl, nicht gut genug zu sein? Woher kommt das und wie kannst du dagegen angehen? Darüber sprechen Dani & Lena in dieser Folge.', itunes: 'https://podcasts.apple.com/de/podcast/selbstzweifel/id1520532938?i=1000490002673', spotify: 'https://open.spotify.com/episode/1VvpESGHklhsBkctE0WPrn', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode10.mp3', shortcode: 's02e11' },
      { title: 'Let´s talk about Sex', description: 'Die große Frage ist doch, wie oft ist okay, normal - gibt es sowas überhaupt? Dani & Lena sprechen offen über Themen wie Sex in der Beziehung, als Single und was ihr machen könnt, wenn es mal nicht läuft.', itunes: 'https://podcasts.apple.com/de/podcast/let-s-talk-about-sex/id1520532938?i=1000490833327', spotify: 'https://open.spotify.com/episode/41vX64YF1iZuwWuweBN9Tb', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode11.mp3', shortcode: 's02e12' },
      { title: 'Vertrauen', description: 'Fällt es dir leicht zu vertrauen oder muss man sich dein Vertrauen erst "erarbeiten"? Was Vertrauen mit uns macht und was hinter Misstrauen wirklich steckt, haben sich Dani & Lena genau angesehen.', itunes: 'https://podcasts.apple.com/de/podcast/vertrauen/id1520532938?i=1000491614338', spotify: 'https://open.spotify.com/episode/1b7Bjzb5tnpuIeqjgy6k2d', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode12.mp3', shortcode: 's02e13' },
      { title: 'Verhütung', description: 'Was gibt es eigentlich alles für Methoden der Verhütung und welche Unterschiede gibt es? Wer trägt die Verantwortung und welche Kosten kommen auf euch zu?', itunes: 'https://podcasts.apple.com/de/podcast/hauptsache-sicher-%C3%BCber-verh%C3%BCtungsmittel/id1520532938?i=1000492420174', spotify: 'https://open.spotify.com/episode/3C20jgfndXiCkCshbIkeSD', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode13.mp3', shortcode: 's02e14' },
      { title: 'Wild oder seriös - welchen Partner ziehe ich an? Part 1', description: 'Flirtcoach Julia Mattes ist zu Gast und spricht über das Mindset bei der Partnersuche', itunes: 'https://podcasts.apple.com/de/podcast/wild-oder-seri%C3%B6s-welchen-partner-ziehe-ich-an-part-1/id1520532938?i=1000493268175', spotify: 'https://open.spotify.com/episode/3vfXpswauFMaShHDP8LCTs', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode15.mp3', shortcode: 's02e15' },
      { title: 'Wild oder seriös - welchen Partner ziehe ich an? Part 2', description: 'Flirtcoach Julia Mattes ist zu Gast und spricht über das Mindset bei der Partnersuche', itunes: 'https://podcasts.apple.com/de/podcast/wild-oder-seri%C3%B6s-welchen-partner-ziehe-ich-an-part-2/id1520532938?i=1000494082153', spotify: 'https://open.spotify.com/episode/5g5GkVHr3THMBeeY73gt6p', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode16.mp3', shortcode: 's02e16' },
      { title: 'Der perfekte Partner', description: 'Idealvorstellungen bei der Suche nach der/dem Richtigen', itunes: 'https://podcasts.apple.com/de/podcast/der-perfekte-partner/id1520532938?i=1000494898364', spotify: 'https://open.spotify.com/episode/2u76P79lE86CWjDcAKvcKC', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode17.mp3', shortcode: 's02e17' },
      { title: 'Was hat Intuition mit Dating zu tun? Folge 3 mit Flirt Coach Julia Mattes', description: 'Kennst du die Stimme deiner Intuition? Es heißt, wir haben ein inneres Ampelsystem, mit dem uns unsere Intuition den Weg weisen will. Viele hören diese innere Stimme nicht oder misstrauen ihr. Wie ihr sie entdeckt, erzählen euch Lena vom Geile Liebe Podcast und Flirt Coach Julia Mattes.', itunes: 'https://podcasts.apple.com/de/podcast/was-hat-intuition-mit-dating-zu-tun-folge-3-mit-flirt/id1520532938?i=1000495709674', spotify: 'https://open.spotify.com/episode/72wwbnd3Tu2ox3seggcuSv', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode18.mp3', shortcode: 's02e18' },
      { title: 'Was hat Intuition mit Dating zu tun? Folge 4 mit Flirt Coach Julia Mattes', description: 'Es heißt, wir haben ein inneres Ampelsystem, mit dem uns unsere Intuition den Weg weisen will. Viele hören diese innere Stimme nicht oder misstrauen ihr. Wie ihr sie entdeckt, erzählen euch Lena und Flirt Coach Julia Mattes.', itunes: 'https://podcasts.apple.com/de/podcast/was-hat-intuition-mit-dating-zu-tun-folge-4-mit-flirt/id1520532938?i=1000496523317', spotify: 'https://open.spotify.com/episode/63FeEOXhasqyCPZEyYT2iC', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode19.mp3', shortcode: 's02e19' },
      { title: 'Partner mit Kind', description: 'Euer Date erzählt euch, dass er*sie ein Kind hat. Dealbreaker oder nicht?', itunes: 'https://podcasts.apple.com/de/podcast/partner-mit-kind/id1520532938?i=1000497385568', spotify: 'https://open.spotify.com/episode/6PJah0ifqcI6J4DgaEGyHV', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode20.mp3', shortcode: 's02e20' },
      { title: 'Ich bin ein Patchworkkind', description: 'Wir teilen unsere Erfahrungen mit euch, wie es ist, mit 2 Vätern oder dem neuen/der neuen Partner/in der Eltern aufzuwachsen. Patchwork-Familien sind heute keine Seltenheit mehr. Welche Erfahrungen habt ihr damit?', itunes: 'https://podcasts.apple.com/de/podcast/ich-bin-ein-patchworkkind/id1520532938?i=1000498388894', spotify: 'https://open.spotify.com/episode/70jqCiOdJc83RbIGG8knWb?si=8j_KQd_8TLiVm8yEOCfhMQ', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode21.mp3', shortcode: 's02e21' },
      { title: 'Ich muss dir nicht gefallen', description: 'Wir unterhalten uns darüber, wie oft wir uns angepasst haben, um die Erwartungen unserer Umwelt zu erfüllen und wie wir es Stück für Stück auflösen konnten.', itunes: 'https://podcasts.apple.com/de/podcast/ich-muss-dir-nicht-gefallen/id1520532938?i=1000499477933', spotify: 'https://open.spotify.com/episode/3Fe6Ltyk5sQ83xeZWcjpuI?si=G4UV15AQTXGlv32lL_syQQ', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode22.mp3', shortcode: 's02e22' },
      { title: 'Streiten in Beziehungen ist super!', description: 'Lutz Friedrich ist Psychologe in Leipzig und beschäftigt sich vor allem mit Kommunikation in Beziehungen. Er gibt uns Einblick in die häufigsten Stolpersteine in Beziehungen und wie man sie aus dem Weg räumen kann.', itunes: 'https://podcasts.apple.com/de/podcast/streiten-in-beziehungen-ist-super-gast-psychologe-lutz/id1520532938?i=1000500397527', spotify: 'https://open.spotify.com/episode/172iukpcbuwMmY18TdqEGc?si=Qk6swQ07RDqb10yFUblxOw', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode23.mp3', shortcode: 's02e23' },
      { title: 'Sexting - heiße Textnachrichten', description: 'Wo fängt Sexting an und wo sind Grenzen, die man nicht überschreiten sollte? Wir teilen unsere Erfahrungen mit euch.', itunes: 'https://podcasts.apple.com/de/podcast/sexting-hei%C3%9Fe-textnachrichten/id1520532938?i=1000501261195', spotify: 'https://open.spotify.com/episode/5UvS3Ww51qz4howwN3WdI0?si=CuPZF8qWTyeUxam31qHtCA', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode24.mp3', shortcode: 's02e24' },
      { title: 'Single Weihnachten - Gast: Flirtcoach Julia Mattes', description: 'Weihnachten ohne Partner - Fluch oder Segen? Wir sprechen mit Flirtcoach Julia Mattes darüber, wie man das Fest der Liebe auch als Single wunderbar feiern kann.', itunes: 'https://podcasts.apple.com/de/podcast/single-weihnachten-gast-flirtcoach-julia-mattes/id1520532938?i=1000501944598', spotify: 'https://open.spotify.com/episode/4fQecXr7Lpz33gC8IELhdV?si=Qln-zJY_SEShGoCDW8LjQw', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode25.mp3', shortcode: 's02e25' },
      { title: 'All I want for Christmas', description: 'Geschenkeschlacht oder besinnlicher Abend? Habt ihr schon alle Geschenke zusammen oder verschenkt ihr dieses Jahr mal nichts? Was tun, wenn man seinen Liebsten etwas schenken will, aber nicht weiß was? Wir helfen euch.', itunes: 'https://podcasts.apple.com/de/podcast/all-i-want-for-christmas/id1520532938?i=1000502731374', spotify: 'https://open.spotify.com/episode/39VVdPe4daN5FqoSpv7SgC?si=vcc3GBvxR86cRg7qzVxKhQ', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode26.mp3', shortcode: 's02e26' },
      { title: 'Religion vs. Dating', description: 'Stehst du zu deinem Glauben auch schon beim Dating? Wir haben uns gefragt, wie offen gehen andere mit ihrer Religiösität oder Spiritualität im Dating um?! Wird es eher versteckt, aus Angst vor Ablehnung, oder wird es offen kommuniziert?', itunes: 'https://podcasts.apple.com/de/podcast/religion-vs-dating/id1520532938?i=1000503423062', spotify: 'https://open.spotify.com/episode/4ZHX1s65qB4nyA8Mp6bLaP?si=pfh4BuRTRuaiwyofzxfk7w', url: 'https://storage.googleapis.com/lv-web.appspot.com/podcasts/GeileLiebe_Episode27.mp3', shortcode: 's02e27' },
    ],
  },
];
