import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as LogoApple } from '../svg/logo_apple.svg';
import { ReactComponent as LogoAndroid } from '../svg/logo_android.svg';
import { ReactComponent as Instagram } from '../svg/instagram.svg';
import { ReactComponent as Facebook } from '../svg/facebook.svg';
import { ReactComponent as Twitter } from '../svg/twitter.svg';
import { ReactComponent as Xing } from '../svg/xing.svg';
import { ReactComponent as LinkedIn } from '../svg/linkedin.svg';

export default class Footer extends Component {
  render() {
    return (
      <Row className="w-100 footer">
        <Col xs={12} lg={3} className="order-2 order-lg-1">
          <Row className="w-100 p-0">
            <Col xs={6} lg={12}>
              <FooterSectionComponent icon={LogoAndroid} title="LOVOO für Android" content={[{ url: 'https://play.google.com/store/apps/details?id=net.lovoo.android&hl=de&gl=US', content: 'Jetzt herunterladen' }]} />
            </Col>
            <Col xs={6} lg={12}>
              <FooterSectionComponent icon={LogoApple} title="LOVOO für iPhone" content={[{ url: 'https://apps.apple.com/de/app/lovoo-dating-app-live-chat/id445338486', content: 'Jetzt herunterladen' }]} />
            </Col>
            <Col xs={6} lg={12}>
              <FooterSectionComponent icon={LogoApple} title="LOVOO für iPad" content={[{ url: 'https://apps.apple.com/de/app/lovoo-dating-app-live-chat/id445338486', content: 'Jetzt herunterladen' }]} />
            </Col>
          </Row>
        </Col>

        <Col xs={12} lg={9} className="order-1 order-lg-2">
          <Row className="w-100 p-0">
            <Col xs={6} lg={4}>
              <FooterSectionComponent
                title="COMPANY"
                content={[
                  { url: 'https://about.lovoo.com/company/karriere/', content: 'Karriere' },
                  { url: 'https://about.lovoo.com/company/business/', content: 'Business' },
                  { url: 'https://about.lovoo.com/company/pressebereich/', content: 'Pressebereich' },
                ]}
              />
            </Col>

            <Col xs={6} lg={4}>
              <FooterSectionComponent
                title="BLOG"
                content={[
                  { url: 'https://about.lovoo.com/category/inside/', content: 'Inside' },
                  { url: 'https://about.lovoo.com/category/unkategorisiert/lovoo-stories/', content: 'User Stories' },
                  { url: 'https://about.lovoo.com/category/appnews/lovoo-live/', content: 'LOVOO Live' },
                ]}
              />
            </Col>

            <Col xs={6} lg={4}>
              <FooterSectionComponent
                title="SUPPORT"
                content={[
                  { url: 'https://support.lovoo.com/hc/de', content: 'Helpcenter' },
                  { url: 'https://support.streamer-tipps.com/hc/de', content: 'Streamer-Tipps' },
                  { url: 'https://www.lovoo.com/fighting-fake/', content: 'Spam, Scam & Fake' },
                ]}
              />
            </Col>

            {/* <Col xs={6} lg={4} className="d-none d-lg-flex">
              <FooterSectionComponent
                title="SHOP"
                content={[
                  { url: '#', content: 'Merch' },
                  { url: '#', content: 'Premium' },
                  { url: '#', content: 'Credits' },
                ]}
              />
            </Col> */}

            <Col xs={6} lg={4}>
              <FooterSectionComponent
                title="RECHTLICHES"
                content={[
                  { url: 'https://www.lovoo.com/imprint', content: 'Impressum' },
                  { url: 'https://www.lovoo.com/legal/toc', content: 'AGB' },
                  { url: 'https://www.lovoo.com/legal/privacy', content: 'Datenschutz' },
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="order-3">
          <SocialMedia cookieWindow={() => this.props.cookieWindow()} />
        </Col>
      </Row>
    );
  }
}

class FooterSectionComponent extends Component {
  render() {
    return (
      <div className={`footer-section ${!this.props.icon ? 'section-padding' : ''}`}>
        {this.props.icon ? <div className="footer-section-icon">{<this.props.icon />}</div> : <></>}
        <div className="footer-section-content">
          <div className="footer-section-headline">{this.props.title || <>&nbsp;</>}</div>
          {this.props.content ? (
            this.props.content.map((el, index) => {
              return (
                <a href={el.url} className="footer-section-text" key={index} target="_blank" rel="noreferrer">
                  {el.content}
                </a>
              );
            })
          ) : (
            <></>
          )}
          {this.props.placeholder ? (
            Array.apply(null, Array(this.props.placeholder)).map((el, index) => {
              return (
                <div className="footer-section-text" key={index}>
                  &nbsp;
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

class SocialMedia extends Component {
  render() {
    return (
      <Row className="socialmedia-section">
        <Col xs={12} md={6} className="socialmedia-icons">
          <a href="https://www.instagram.com/lovooapp/" alt="LOVOO at Instagram" target="_blank" rel="noreferrer">
            <Instagram className="socialmedia-icon" />
          </a>
          <a href="https://www.facebook.com/lovoo" alt="LOVOO at Facebook" target="_blank" rel="noreferrer">
            <Facebook className="socialmedia-icon" />
          </a>
          <a href="https://twitter.com/lovoo" alt="LOVOO at Twitter" target="_blank" rel="noreferrer">
            <Twitter className="socialmedia-icon" />
          </a>
          <a href="https://www.xing.com/pages/lovoo" alt="LOVOO at Xing" target="_blank" rel="noreferrer">
            <Xing className="socialmedia-icon" />
          </a>
          <a href="https://www.linkedin.com/company/lovoo-gmbh" alt="LOVOO at LinkedIn" target="_blank" rel="noreferrer">
            <LinkedIn className="socialmedia-icon" />
          </a>
        </Col>
        <Col xs={12} md={6} className="socialmedia-closure">
          <span onClick={() => this.props.cookieWindow()} style={{ cursor: 'pointer' }}>
            Cookie-Einstellungen
          </span>
          <br />
          &copy;&nbsp;LOVOO&nbsp;GmbH&nbsp;{new Date(Date.now()).getFullYear() || 2021}. Alle&nbsp;Rechte&nbsp;vorbehalten.
        </Col>
      </Row>
    );
  }
}
