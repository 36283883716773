import React, { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Switch from 'react-switch';
import { Cookies, cookieDraft, gaTag } from '../utils/cookies';

export default class CookieConsent extends Component {
  state = { switchStates: { essential: true, statistic: false }, showOptions: false };

  componentDidMount = () => {
    if (this.props.show) {
      this.setState({ showOptions: true });
    }

    const getCookies = Cookies.get();
    if (Object.keys(getCookies).length > 0) {
      Object.keys(getCookies).forEach((id) => {
        this.setState((prevState) => ({
          switchStates: {
            ...prevState.switchStates,
            [id]: getCookies[id],
          },
        }));
      });
    }
  };

  setCookies = (declined = false, acceptAll = false) => {
    if (declined) {
      Cookies.set({ essential: true, statistic: false }, 365);
    }
    if (acceptAll) {
      Cookies.set({ essential: true, statistic: true }, 365);
    }
    if (!declined && !acceptAll) {
      Cookies.set(this.state.switchStates, 365);
    }

    if (this.state.statistic) {
      window[`ga-disable-${gaTag}`] = false;
    } else {
      window[`ga-disable-${gaTag}`] = true;
    }

    this.props.setShow(!this.props.showCookie);
  };

  render = () => {
    if (Object.keys(Cookies.get()).length > 0 && !this.props.showCookie) {
      return true;
    } else {
      return (
        <div className="blockcontent">
          <AnimatePresence initial={true} exitBeforeEnter>
            <motion.div className="cookieplacer" initial={{ y: '100vh' }} animate={{ y: '0vh' }} exit={{ y: '100vh' }} transition={{ type: 'spring', bounce: 0 }}>
              <div className="cookieconsent">
                <div className="titlecookies">
                  <h1>Cookie Consent</h1>
                  <p>
                    Auf dieser Website werden Cookies genutzt. Einige von ihnen sind essenziell, während andere helfen, diese Website und Ihre Erfahrung zu verbessern.{' '}
                    <a href="https://www.lovoo.com/legal/privacy" target="_blank" rel="noreferrer" style={{ color: 'var(--lvo-blue)', textDecoration: 'none' }}>
                      Datenschutz
                    </a>
                    &nbsp;
                    <a href="https://www.lovoo.com/imprint" target="_blank" rel="noreferrer" style={{ color: 'var(--lvo-blue)', textDecoration: 'none' }}>
                      &amp;&nbsp;Impressum
                    </a>
                  </p>
                </div>
                <div className="scrollcookie">
                  <motion.div className="cookie-customcontainer" initial={{ height: '0px' }} animate={{ height: this.state.showOptions ? 'auto' : '0px' }} exit={{ height: '0px' }}>
                    {cookieDraft.map(({ title, description, id }, index) => {
                      return (
                        <div key={index} className="cookie-custom-element">
                          <div className="cookie-custom-elemt-content">
                            <h1>{title}</h1>
                            <div className="cookie-custom-elemt-switch">
                              <Switch
                                className="consent-switch"
                                checked={this.state.switchStates[id]}
                                onChange={() => {
                                  this.setState((prevState) => ({
                                    switchStates: {
                                      ...prevState.switchStates,
                                      [id]: this.state.switchStates[id] ? false : true,
                                    },
                                  }));
                                }}
                                disabled={id === 'essential'}
                                onColor={'#1ece50'}
                                offColor={'#444444'}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                activeBoxShadow={'0 0 0 0 #000'}
                                height={21}
                                width={48}
                              />
                            </div>
                          </div>
                          <p>{description}</p>
                        </div>
                      );
                    })}
                  </motion.div>
                </div>
                <div className="fixCookie noselect">
                  <div className="buttoncontainer">
                    <button
                      type="button"
                      className="lvo-button"
                      style={{ border: 'none', color: 'var(--lvo-white)', margin: 0 }}
                      onClick={() => {
                        if (this.state.showOptions) {
                          this.setCookies(false, false);
                        } else {
                          this.setCookies(false, true);
                        }
                      }}>
                      {this.state.showOptions ? 'Auswahl akzeptieren' : 'Alle akzeptieren'}
                    </button>
                  </div>
                  <div className="secondarybuttoncontainer noselect">
                    <div
                      onClick={() => {
                        if (this.state.showOptions) {
                          this.setState({ showOptions: !this.state.showOptions });
                        } else {
                          this.setCookies(true, false);
                        }
                      }}>
                      {this.state.showOptions ? 'Abbrechen' : 'Ablehnen'}
                    </div>
                    <div className="consent-spacer">|</div>
                    <div
                      onClick={() => {
                        this.setState({ showOptions: this.state.showOptions ? false : true });
                      }}>
                      Individuelle Einstellungen
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      );
    }
  };
}
