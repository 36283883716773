export const cookieDraft = [
  { title: 'Essenziell', description: 'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.', id: 'essential' },
  { title: 'Statistik', description: 'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.', id: 'statistic' },
];

const cookieName = 'landingpage.lovoo.com-cookieConsents';

export const Cookies = {
  set: function (value, days, name = cookieName) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (JSON.stringify(value) || '{}') + expires + '; path=/';
  },
  get: function (name = cookieName) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return JSON.parse(c.substring(nameEQ.length, c.length));
      }
    }
    return {};
  },
  clear: function (name = cookieName) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
};

export const gaTag = 'UA-26475682-1';
