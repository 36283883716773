import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import AudioPlayer from 'react-h5-audio-player';
import Share from '../components/share';
import ReactGA from 'react-ga';

import PlayIcon from '../svg/play.svg';
import PauseIcon from '../svg/pause.svg';
import NextIcon from '../svg/next.svg';
import PrevIcon from '../svg/previous.svg';

import { podcasts } from '../utils/podcasts';

export default class PodcastPlayer extends Component {
  state = { isPlaying: null, plays: false };

  nextFile = (autoplay) => {
    if (this.props.currentEpisode + 1 > podcasts[this.props.currentPodcast].episodes.length - 1) {
      this.props.setCurrentEpisode(0);
      this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[0].url, title: podcasts[this.props.currentPodcast].episodes[0].title }, plays: autoplay || this.state.plays });
    } else {
      this.props.setCurrentEpisode(this.props.currentEpisode + 1);
      this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode + 1].url, title: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode + 1].title }, plays: autoplay || this.state.plays });
    }
  };

  prevFile = () => {
    if (this.props.currentEpisode === 0) {
      this.props.setCurrentEpisode(podcasts[this.props.currentPodcast].episodes.length - 1);
      this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[podcasts[this.props.currentPodcast].episodes.length - 1].url, title: podcasts[this.props.currentPodcast].episodes[podcasts[this.props.currentPodcast].episodes.length - 1].title } });
    } else {
      this.props.setCurrentEpisode(this.props.currentEpisode - 1);
      this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode - 1].url, title: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode - 1].title } });
    }
  };

  componentDidMount = () => {
    if (this.props.currentEpisode) {
      this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].url, title: podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].title, podcast: podcasts[this.props.currentPodcast].title }, currentPodcast: this.props.currentPodcast });
    
    }
  };
  
  startFile = () => {
    this.props.setCurrentEpisode(podcasts[this.props.currentPodcast].episodes.length - 1);
    this.setState({ isPlaying: { ...this.state.isPlaying, url: podcasts[this.props.currentPodcast].episodes[0].url, title: podcasts[this.props.currentPodcast].episodes[0].title } || this.state.plays });
  }

  render() {
    if (!podcasts || podcasts[this.props.currentPodcast].episodes.length -1 < this.props.currentEpisode) {
      this.props.setCurrentEpisode(0);
      return <></>;
    } else {
      return (
        <div className="podcast-player">
          <Row className="w-100 p-0 m-0">
            <Col xs={12} lg="auto" className="episode-image mb-4">
              <div style={{ backgroundImage: `url('${podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].cover || podcasts[this.props.currentPodcast].cover}')` }}></div>
            </Col>
            <Col xs={12} lg="auto" className="episode-queue p-0">
              <Row className="w-100 p-0 m-0">
                <Col xs={12} className="p-0">
                  <h1>Alle Folgen:</h1>
                </Col>
                <Col xs={12} className="p-0">
                  <SimpleBar className="podcast-queue" autoHide={false}>
                    {podcasts[this.props.currentPodcast].episodes.map((episode, index) => {
                      return (
                        <div key={index}>
                          <div
                            className="queue-element"
                            onClick={() =>
                              this.setState({ isPlaying: { url: episode.url, title: episode.title, podcast: podcasts[this.props.currentPodcast].title } }, () => {
                                this.props.setCurrentEpisode(index);
                                ReactGA.event(`Playback Podcast-Episode: ${this.state.isPlaying.podcast}–${this.state.isPlaying.title}`, 'landgingpage.lovoo.com/podcast');
                                window.location.hash = `s${parseInt(this.props.currentPodcast) + 1 <= 9 ? '0' + (parseInt(this.props.currentPodcast) + 1) : parseInt(this.props.currentPodcast) + 1}e${index + 1 <= 9 ? '0' + (index + 1) : index + 1}`;
                              })
                            }>
                            <h3 style={{ fontWeight: index === this.props.currentEpisode ? 600 : 400 }}>
                              {`${index + 1}. `}
                              {episode.title}
                            </h3>
                          </div>
                          {podcasts[this.props.currentPodcast].episodes.length - 1 !== index ? <hr /> : <></>}
                        </div>
                      );
                    })}
                  </SimpleBar>
                  <div className="podcast-queue-fader"></div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="w-100 p-0 m-0">
            <Col xs={12} className="episode-text p-0">
              <div className="expisode-head-container">
                <h2>{podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].title || 'Titel der Folge'}</h2>
              </div>
              <p>{podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].description || 'Beschreibung der Folge'}</p>
            </Col>
          </Row>
          <Row className="w-100 p-0 m-0">
            <Share />
          </Row>
          <Row className="w-100 p-0 m-0">
            <Col xs={12} className="p-0">
              <AudioPlayer
                autoPlay={true}
                onEnded={(e) => this.nextFile() && this.setState({ plays: false })}
                onPlay={() =>  this.setState({ plays: true }, () => ReactGA.event(`Playback Podcast-Episode: ${this.state.isPlaying.podcast}–${this.state.isPlaying.title}`, 'landingpage.lovoo.com/podcast')), console.log(podcasts[this.props.currentPodcast].episodes[this.props.currentEpisode].title)}
                onPause={() => this.setState({ plays: false })}
                autoPlayAfterSrcChange={this.state.plays}
                onClickPrevious={() => this.prevFile()}
                onClickNext={() => this.nextFile()}
                showSkipControls={true}
                showJumpControls={false}
                showFilledProgress={true}
                header={
                  this.state.isPlaying && this.state.isPlaying.title ? (
                    <> 
                      <b>{this.state.isPlaying.podcast || podcasts[this.props.currentPodcast].title}:</b> {this.props.currentEpisode + 1}.{this.state.isPlaying.title}
                    </>
                  ) : (
                    ''
                  )
                }
                src={this.state.isPlaying && this.state.isPlaying.url }
                customIcons={{ play: <img src={PlayIcon} width="auto" height="auto" alt="" />, pause: <img src={PauseIcon} width="auto" height="auto" alt=""/>, next: <img src={NextIcon} width="auto" height="auto" alt="" />, previous: <img src={PrevIcon} width="auto" height="auto" alt="" /> }}
              />
            </Col>
          </Row>
        </div>
      );
    }
  }
}