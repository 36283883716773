import React, { Component, createRef } from 'react';

export default class Shape extends Component {
  constructor(props) {
    super(props);
    this.container = createRef();
  }

  state = { height: 10, width: 10, uid: '', isMobile: false };

  create_UUID = () => {
    return new Promise((resolve, reject) => {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      resolve(uuid);
    });
  };

  componentDidMount = () => {
    this.create_UUID().then((uid) => {
      this.setState({ uid: uid });
    });
    this.setState({ height: this.container.current.offsetHeight, width: this.container.current.offsetWidth, isMobile: false });
    this.resizeObserver();
  };

  resizeObserver = () => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        this.updateIsMobile(entries[0].target.offsetWidth);
        this.setState({ width: entries[0].target.offsetWidth, height: entries[0].target.offsetHeight });
      }
    });
    observer.observe(this.container.current);
  };

  updateIsMobile = (width) => {
    if (width <= 576 && !this.state.isMobile) {
      this.setState({ isMobile: true }, () => console.log('set to mobile'));
    }

    if (width > 576 && this.state.isMobile) {
      this.setState({ isMobile: false }, () => console.log('set to desktop'));
    }
  };

  render() {
    return (
      <>
        <div ref={this.container} className="clip" style={{ background: this.props.background || 'var(--lvo-grad-red-yellow)', clipPath: `url(#${this.state.uid}${this.state.isMobile ? 'xs' : ''})`, width: '100%', height: 'auto', transform: 'translateY(-102px)' }}>
          {this.props.children}
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute' }}>
          <defs>
            <clipPath id={this.state.uid}>
              <path
                d={`
                M974.5,29.66,504.5,101
                a30.17,30.17,0,0,1-9,0
                L25.5,29.66
                A30,30,0,0,1,0,0
                V${this.props.height || this.state.height - 102 || 100}
                a30,30,0,0,0,25.5,29.66
                l470,71.37
                a30.17,30.17,0,0,0,9,0
                l470-71.37
                a30,30,0,0,0,25.49-29.66
                V0
                A30,30,0,0,1,974.5,29.66
                Z`}
                transform={`scale(${parseInt(this.props.width || this.state.width) / 1000 || 1}, ${1})`}
              />
            </clipPath>
          </defs>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute' }}>
          <defs>
            <clipPath id={this.state.uid + 'xs'}>
              <path
                d={`
                M377,0
                V${this.props.height - 95 || this.state.height - 95 || 100}
                a30,30,0,0,1-18.79,27.83
                l-158.5,63.84
                a30.07,30.07,0,0,1-22.42,0
                L18.79,${this.props.height - 95 + 27.82 || this.state.height - 95 + 27.82 || 127.82}
                A30,30,0,0,1,0,${this.props.height - 95 || this.state.height - 95 || 100}
                V0
                A30,30,0,0,0,18.79,27.82
                l158.5,63.84
                a30,30,0,0,0,22.42,0
                l158.5-63.84
                A30,30,0,0,0,377,0
                Z
                `}
                transform={`scale(${parseInt(this.props.width || this.state.width) / 377 || 1}, ${1})`}
              />
            </clipPath>
          </defs>
        </svg>
      </>
    );
  }
}
