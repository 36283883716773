import Shape from './components/shape';
import { ReactComponent as SpotifyLogo } from './svg/spotify_logo.svg';
import { ReactComponent as ItunesLogo } from './svg/applepodcast_logo.svg';
import Footer from './components/footer';
import Albums from './components/albums';
import PodcastPlayer from './components/podcastplayer';
import { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Cookies, gaTag } from './utils/cookies';
import CookieConsent from './components/cookieconsent';
import ReactGA from 'react-ga';
import { podcasts } from './utils/podcasts';

import ScrollableAnchor from 'react-scrollable-anchor'


if (window.location.host !== 'localhost:3000') {
  ReactGA.initialize(gaTag);
  ReactGA.set({ anonymizeIp: true });
}
window[`ga-disable-${gaTag}`] = true;


class App extends Component {
  state = { currentPodcast: 0, animate: 0, currentEpisode: 0, linked: false, openCookie: false, cookies: {} };

  handleCookieWindow = () => {
    this.setState({ openCookie: !this.state.openCookie });
  };

  initAnalytics = (mounted = false) => {
    var cookies = Cookies.get();
    if (JSON.stringify(this.state.cookies) !== JSON.stringify(cookies)) {
      this.setState({ cookies: cookies }, () => {
        if (Object.keys(this.state.cookies).length > 0) {
          if (this.state.cookies['statistic']) {
            window[`ga-disable-${gaTag}`] = false;
            if (mounted) {
              ReactGA.pageview('Podcast Landingpage', 'landgingpage.lovoo.com/podcast', 'Podcast Seitenaufruf');
            }
          } else {
            window[`ga-disable-${gaTag}`] = true;
          }
        }
      });
    }
  };

  selectPodcast = (podcast) => {
    if (this.state.currentPodcast !== podcast) {
      ReactGA.event(`Podcast selected: ${podcasts[podcast].title}`, 'landgingpage.lovoo.com/podcast');
    }
    this.setState({ animate: podcast }, () => this.setState({ currentPodcast: podcast }));
  };

  setCurrentPodcast = (podcast) => {
    this.setState({ currentPodcast: podcast });
  };

  setCurrentEpisode = (episode) => {
    this.setState({ currentEpisode: episode });
  };

  componentWillMount = () => {
    if (window.location.hash && window.location.hash.length === 7 && window.location.hash.includes('s') && window.location.hash.includes('e')) {
      try {
        var podcast = window.location.hash.replace('#', '').split('e');
        podcast[0] = parseInt(podcast[0].split('s')[1]) - 1;
        podcast[1] = parseInt(podcast[1]) - 1;
        if (podcast[0] >= podcasts.length) {
          podcast[0] = 0;
        }
        if (podcast[1] >= podcasts[podcast[0]].episodes.length) {
          podcast[1] = 0;
        }
        this.setState({ currentEpisode: podcast[1], currentPodcast: podcast[0], animate: podcast[0] });
      } catch (err) {
        this.setState({ currentPodcast: 0, currentEpisode: 0 });
      }
    }
  };

  componentDidUpdate = () => {
    this.initAnalytics();
    if (window.location.hash && !this.state.linked) {
      if (window.location.hash && window.location.hash.length === 7 && window.location.hash.includes('s') && window.location.hash.includes('e')) {
        try {
          var podcast = window.location.hash.replace('#', '').split('e');
          podcast[0] = parseInt(podcast[0].split('s')[1]) - 1;
          podcast[1] = parseInt(podcast[1]) - 1;
          if (podcast[0] >= podcasts.length) {
            podcast[0] = 0;
          }
          if (podcast[1] >= podcasts[podcast[0]].episodes.length) {
            podcast[1] = 0;
          }
          this.setState({ currentEpisode: podcast[1], currentPodcast: podcast[0], animate: podcast[0] });
        } catch (err) {
          this.setState({ currentPodcast: 0, currentEpisode: 0 });
        }
        this.setState({ linked: true });
      }
    }
  };

  componentDidMount = () => {
    this.initAnalytics(true);
  };





  render() {

    return (
      <>

        <CookieConsent setShow={() => this.handleCookieWindow()} showCookie={this.state.openCookie} />
        <AnimatePresence>
          <div className="root-container">
           
            <div className="header-image" style={{ width: '100%' }} >
              {/* <img src={PodcastLogo} alt="LOVOO Podcast Logo" className="podcast-logo d-none d-sm-block" />
              <img src={PodcastLogoWhite} alt="LOVOO Podcast Logo" className="podcast-logo-white d-sm-none" /> */}
            </div>
     
            <Shape background="var(--lvo-grad-blue-blue)">
              <Albums selectPodcast={this.selectPodcast} setCurrentPodcast={this.setCurrentPodcast} isLinked={this.state.currentPodcast} onClick={this.scroll} />
              <motion.div className="shape-content albums-patting-0" 
                  initial={{ height: 0, opacity: 1 }} 
                  animate={{ height: 'fit-content', opacity: this.state.currentPodcast !== this.state.animate ? 0 : 1 }} 
                  transition={{ duration: 0.2 }} 
                  onAnimationComplete={() => this.setState({ currentPodcast: this.state.animate })}>
                <ScrollableAnchor id={'Album'}>
                  <h1>{this.state.currentPodcast >= 0 ? podcasts[this.state.currentPodcast].title || 'Podcast Titel' : 'Podcast Titel'}</h1>
                </ScrollableAnchor>
                <p>{this.state.currentPodcast >= 0 ? podcasts[this.state.currentPodcast].description || 'Podcast Beschreibung' : 'Podcast Beschreibung'}</p>
                <div className="external-podcast">
                  {podcasts[this.state.currentPodcast] && podcasts[this.state.currentPodcast].podcast_itunes ? (
                    <ItunesLogo
                      onClick={() => {
                        ReactGA.event(`Podcast iTunes Link used: ${podcasts[this.state.currentPodcast].title}`, 'landgingpage.lovoo.com/podcast');
                        window.open(podcasts[this.state.currentPodcast].podcast_itunes, '_blank');
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {podcasts[this.state.currentPodcast] && podcasts[this.state.currentPodcast].podcast_spotify ? (
                    <SpotifyLogo
                      onClick={() => {
                        ReactGA.event(`Podcast Spotify Link used: ${podcasts[this.state.currentPodcast].title}`, 'landgingpage.lovoo.com/podcast');
                        window.open(podcasts[this.state.currentPodcast].podcast_spotify, '_blank');
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                
                <PodcastPlayer id="Album" currentPodcast={this.state.currentPodcast} currentEpisode={this.state.currentEpisode} setCurrentPodcast={(podcast) => this.setCurrentPodcast(podcast)} setCurrentEpisode={(episode) => this.setCurrentEpisode(episode)} />
                
              </motion.div>
            </Shape>
            <Footer cookieWindow={() => this.handleCookieWindow()} />
          </div>
        </AnimatePresence>
      </>
    );
  } 
}
export default App;
